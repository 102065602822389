const whoami = {
  firstName: "Omar",
  lastName: "Olmos",
  title: "Software Developer",
  school: "University of California Merced",
  study: "Computer Science & Engineering",
  degree: "Bachelor of Science",
  summary1:
    "In this evolving landscape of Full Stack development, my journey continues to be guided by an insatiable curiosity, a thirst for knowledge, and an unyielding passion to build meaningful digital experiences.",
  summary2:
    "My skills in React, JavaScript, Java, and Flutter serve as the cornerstones of my journey, enabling me to architect the future, one line of code at a time.",

  sections: [
    "Welcome to my Portfolio!",
    "My name is Omar Olmos.",
    "I have a Bachelor of Science degree in Computer Science & Engineering from the University of California Merced.",
    "In this evolving landscape of Full Stack development, my journey continues to be guided by an insatiable curiosity, a thirst for knowledge, and an unyielding passion to build meaningful digital experiences.",
  ],
  certificates: [
    {
      name: "Google's IT Support",
      link: "https://www.credly.com/badges/d869a5b7-10cd-4ce2-ad1f-542364ba7d9c?source=linked_in_profile",
    },
  ],
  links: [
    {
      name: "Linkedin",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png",
      link: "https://www.linkedin.com/in/oolmos/",
    },
    {
      name: "Github",
      image: "https://cdn-icons-png.flaticon.com/512/25/25231.png",
      link: "https://github.com/oolmos98",
    },
  ],
};
export default whoami;
